export type ChangeLogEntry = {
	title: string;
	description: string;
};

export type ChangeLog = {
	date: Date;
	entries: Array<ChangeLogEntry>;
};

export const changeLog: Array<ChangeLog> = [
	{
		date: new Date('2025-04-03'),
		entries: [
			{
				title: 'Adds Reservations For Quick Customer Lookups',
				description:
					'Reservations with customers will now show up on the customers tab. This only applies to facilities that are bookable on troon.com.',
			},
		],
	},
	{
		date: new Date('2025-03-24'),
		entries: [
			{
				title: 'Troon Rewards Transaction Analytics',
				description: 'Troon Rewards transaction analytics are now available.',
			},
		],
	},
	{
		date: new Date('2025-03-12'),
		entries: [
			{
				title: 'Rewards Level Override',
				description: "Facility admins can now override a user's rewards level.",
			},
			{
				title: 'Guest Pass Redemption',
				description: 'Operators can now redeem Troon Access guest passes from their profile page.',
			},
		],
	},
	{
		date: new Date('2025-03-11'),
		entries: [
			{
				title: 'Troon Rewards Signup Analytics',
				description: 'Brought back analytics for Troon Rewards signups. Rewards points coming soon.',
			},
			{
				title: 'Allow Customizing Reward Transaction Dates',
				description:
					'Operators can now customize the date of a reward transaction. In addition, this can be updated after a transaction has been created.',
			},
			{
				title: 'Fixed Updating Rewards User Profiles',
				description:
					'Previously, the save button was sometimes below the screen and could not be clicked. This has been fixed.',
			},
		],
	},
	{
		date: new Date('2025-03-10'),
		entries: [
			{
				title: 'Navigation Improvements',
				description: 'Fixed facility navigation to wait for facility data to load before building the nav menu.',
			},
			{
				title: 'Preserved Customer Searches',
				description: 'Customer search queries are now preserved when navigating back from customer profiles.',
			},
		],
	},
	{
		date: new Date('2025-02-24'),
		entries: [
			{
				title: 'Troon Access Tags',
				description: 'Enhanced customer listings with formatted Troon Access tags instead of enum values.',
			},
		],
	},
	{
		date: new Date('2025-02-20'),
		entries: [
			{
				title: 'Resources Page Updates',
				description: 'Added Troon Rewards and Operator FAQs to the resources page.',
			},
			{
				title: 'Customer Profile Enhancements',
				description: 'Added points by year view and signup date to customer profiles.',
			},
		],
	},
	{
		date: new Date('2025-02-19'),
		entries: [
			{
				title: 'User Profile Editing',
				description: 'Operators can now edit customer names and profile information.',
			},
		],
	},
];
